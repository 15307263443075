<template>
  <div>

    <!-- modello -->
    <div class="p-fluid">
      <div class="field grid">
        <label for="event" class="col-1" style="width:3vw">{{ t('label.modelloemail.event') }}</label>
        <div class="col-4">
          <Dropdown id="modello" name="event"
                    v-model="selectedModello"
                    :options="modelli"
                    optionLabel="tipo"
                    :placeholder="t('message.select.email-template')"/>
        </div>
      </div>
    </div>

    <!-- lingue -->
    <TabView ref="languageTabView" :active-index="activeIndex">
      <TabPanel :header="t('label.locale.english')">

        <div class="p-fluid">

          <!-- soggetto -->
          <div class="field">
            <label for="soggetto">{{ t('label.modelloemail.soggetto') }}</label>
            <InputText id="soggetto" name="soggetto" type="text" v-model="selectedModello.oggettoEn"
                       :class="errorStyleClass(v$, 'oggettoEn')"/>
            <ErrorMessages field="oggettoEn"/>
          </div>

          <!-- template -->
          <div class="field">
            <label for="template">{{ t('label.modelloemail.template') }}</label>
            <Editor id="template" name="template" v-model="selectedModello.templateEn"
                    :class="errorStyleClass(v$, 'template')" editorStyle="height: 320px">
              <template #toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <!--<div class="p-inputgroup">
                  <Dropdown v-model="selectedVariable" :options="variables" optionLabel="label" optionValue="value"
                            :placeholder="t('message.select.variable')"/>-->
                   </span>
              </template>
            </Editor>
            <ErrorMessages field="templateEn"/>
          </div>
        </div>

      </TabPanel>
    </TabView>

    <div class="mt-2">
      <Button :label="t('action.save')" icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="saveTemplate"/>
    </div>

  </div>
</template>

<script setup>
import {onMounted, provide} from "vue";
import {ref} from "vue";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import useCommon from "@/composable/useCommon";
import ErrorMessages from "@/components/common/ErrorMessages";
import useMessage from "@/composable/useMessage";
import modelloEmailAPI from "@/services/ModelloEmailAPI";
import {isEmpty} from "lodash/lang";
import {useI18n} from "vue-i18n";

    const {errorStyleClass} = useCommon();
    const {t} = useI18n();
    const {success} = useMessage();

    const activeIndex = 0;
    const modelli = ref([]);
    const selectedModello = ref({});

    //const variables = [{label: "Indirizzo ", value: "${address}"}];
    //const selectedVariable = ref();

    const rules = {
      oggettoEn: {required: helpers.withMessage(t("validation.required", {field: t("label.modelloemail.soggetto")}), required)},
      templateEn: {required: helpers.withMessage(t("validation.required", {field: t("label.modelloemail.template")}), required)},
    };
    const v$ = useVuelidate(rules, selectedModello);

    onMounted(async () => {
      modelli.value = await modelloEmailAPI.findAllAttivi();
      if (!isEmpty(modelli.value) && modelli.value.length > 0) {
        selectedModello.value = modelli.value[0];
      }
    });

    /*const addVariable = () => {
      if (activeIndex === 0 && isEmpty(selectedModello.value.templateEn)) {
        selectedModello.value.templateEn = selectedVariable.value;
      } else {
        selectedModello.value.templateEn += selectedVariable.value;
      }
    }*/

    const saveTemplate = async () => {
      await v$.value.$validate();
      if (!v$.value.$invalid) {
        await modelloEmailAPI.update(selectedModello.value);
        success('');
      }

    }


    provide('v$', v$);
</script>

<style scoped>

</style>
